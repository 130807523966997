<template>
  <div class="supervise">
    <div class="header">
      <div class="header_top">
        <img src="../../assets/invoice.png"
             alt="" />
        <span class="center">发票管理</span>
        <img src="../../assets/right.png"
             alt="" />
        <span class="center">发票列表</span>
      </div>
      <div style="display: flex; align-items: center">
        <span class="seach_text">关键字:</span>
        <el-input placeholder="请输入发票申请企业名称"
                  v-model="keywords"
                  @input="getInvoiceList('seath')"
                  size="mini"
                  style="margin-right: 10px; width: 250px"
                  prefix-icon="el-icon-search"
                  clearable>
        </el-input>
      </div>
    </div>
    <el-table :data="invoiceList"
              style="width: 100%">
      <el-table-column label="发票申请编号"
                       width="120">
        <template slot-scope="scope">
          <span class="font-color-brand"
                v-prev
                path="/system/invoice/platform-invoice-info"
                :query="{ code: scope.row.applyCode }">{{ scope.row.applyCode }}
          </span>
        </template>
      </el-table-column>
      <el-table-column label="发票号及编码"
                       width="200">
        <template slot-scope="scope">
          <div>
            发票编号：<span class="clickColor">{{
              scope.row.invoiceCode
            }}</span>
          </div>
          <div>
            发票编码：<span class="clickColor">{{
              scope.row.invoiceNumber
            }}</span>
          </div>
          <div>
            发票图片：<a :href="scope.row.path"
               target="_blank">
              <el-button type="text">查看</el-button>
            </a>
          </div>
        </template>
      </el-table-column>

      <el-table-column label="购买方">
        <template slot-scope="scope">
          <div>名称：{{ scope.row.draweeName }}</div>
          <div>
            统一信用代码：<span class="clickColor">{{
              scope.row.draweeTaxNumber
            }}</span>
          </div>
          <!-- <div>地址、电话：{{ scope.row.draweePhone }}</div>
          <div>开户行及帐号：{{ scope.row.draweeBank }}</div> -->
        </template>
      </el-table-column>
      <el-table-column label="销售方">
        <template slot-scope="scope">
          <div>名称：{{ scope.row.salesName }}</div>
          <div>
            统一信用代码：<span class="clickColor">{{
              scope.row.salesTaxNumber
            }}</span>
          </div>
          <!-- <div>地址、电话：{{ scope.row.salesPhone }}</div>
          <div>开户行及帐号：{{ scope.row.salesBank }}</div> -->
        </template>
      </el-table-column>

      <el-table-column label="金额/元"
                       width="150">
        <template slot-scope="scope">
          <span class="clickColor">{{ scope.row.pureAmount | money }}</span>
        </template>
      </el-table-column>

      <el-table-column label="税额/元"
                       width="150">
        <template slot-scope="scope">
          <span class="clickColor"> {{ scope.row.tax | money }}</span>
        </template>
      </el-table-column>

      <el-table-column label="合计/元"
                       width="150">
        <template slot-scope="scope">
          <span class="clickColor"> {{ scope.row.amount | money }}</span>
        </template>
      </el-table-column>
    </el-table>
    <div class="pagination">
      <span class="clickColor"> 共{{ total }}条</span>
      <span class="clickColor"
            style="margin:0 10px">
        每页{{ pageSize }}条</span>
      <el-pagination background
                     :current-page.sync="pageNumber"
                     @size-change="handleSizeChange"
                     @current-change="handleCurrentChange"
                     :page-size="pageSize"
                     prev-text="上一页"
                     next-text="下一页"
                     layout="prev,pager, next,jumper"
                     :total="total">
      </el-pagination>
    </div>
  </div>
</template>

<script>
//接口：
import { api } from '/src/api/base';
//新增接口
export const invoiceList = api()('invoice.list.json');

export default {
  data () {
    return {
      keywords: '',
      invoiceList: [],
      pageNumber: 1,
      pageSize: 10,

      total: 0
    };
  },
  created () {
    this.getInvoiceList();
  },
  methods: {
    handleSizeChange (val) {
      this.pageSize = val;

      this.getInvoiceList();
    },
    handleCurrentChange (val) {
      this.pageNumber = val;
      this.getInvoiceList();
    },
    async getInvoiceList (seath) {
      if (seath) {
        this.pageNumber = 1;
      }
      const result = await invoiceList({
        keywords: this.keywords,
        pageNumber: this.pageNumber,
        pageSize: this.pageSize
      });
      this.invoiceList = result.list || [];
      this.total = result.total || 0;
    },
    pageChange (val) {
      this.pageNumber = val;
      this.getInvoiceList();
    }
  }
};
</script>
